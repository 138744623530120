/** @jsx jsx */

import Layout from '../components/Layout'
import React from 'react'
import { graphql } from 'gatsby'
import { Gedicht } from '../components/Gedicht'
import SEO from '../components/Seo'
import { css, jsx } from '@emotion/react'
import { variables } from '../styles/global'
import { GatsbyImage } from 'gatsby-plugin-image/'

export function Events({ data }: any) {
  const pageData = data.allDatoCmsEvent.edges[0].node

  return (
    <Layout>
      <SEO title="Events" />
      <div className="container">
        <div
          css={css`
            display: flex;
            width: 100%;
            ${variables.mobile} {
              display: block;
            }
          `}
        >
          <div
            css={css`
              width: 50%;
              margin-right: 30px;
              margin-top: 30px;

              ${variables.mobile} {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 30px;
              }
            `}
          >
            <div
              css={css`
                margin-bottom: 30px;
                ${variables.mobile} {
                  text-align: justify;
                }
              `}
              dangerouslySetInnerHTML={{ __html: pageData.introText }}
            ></div>

            <GatsbyImage
              image={pageData.foto.gatsbyImageData}
              alt={'Event foto'}
            />
          </div>
          <div
            css={css`
              width: 50%;
              margin-left: 30px;
              ${variables.mobile} {
                width: 100%;
                margin-left: 0px;
              }
            `}
          >
            <h2>{pageData.titel}</h2>
            {pageData.events.map((event: any, index: number) => {
              if (!event.isafgelopen) {
                return <Event key={index} event={event} />
              } else return null
            })}
            <h2
              css={css`
                margin-top: 100px;
              `}
            >
              Voorgaande events:
            </h2>
            {pageData.events.map((event: any, index: number) => {
              if (event.isafgelopen) {
                return <PastEvent key={index} event={event} />
              } else return null
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Event({ event }: any) {
  console.log(event)
  return (
    <div
      css={css`
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
      `}
    >
      <h3
        css={css`
          display: block;
          margin-bottom: -10px;
        `}
      >
        {event.titel}
      </h3>
      <p>{event.beschrijving}</p>
      {event.link && (
        <a
          css={css`
            text-decoration: underline;
          `}
          href={event.link}
          target="_blank"
        >
          Meer info
        </a>
      )}
    </div>
  )
}

function PastEvent({ event }: any) {
  console.log(event)
  return (
    <div
      css={css`
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
      `}
    >
      <p>{event.titel}</p>
    </div>
  )
}

export default Events

export const query = graphql`
  query eventsQuery {
    allDatoCmsEvent {
      edges {
        node {
          titel
          introText
          foto {
            gatsbyImageData
          }
          events {
            beschrijving
            isafgelopen
            link
            titel
          }
        }
      }
    }
  }
`
